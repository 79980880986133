header {
  .header__title {
    margin: 0;

    font-size: var(--font-size--md);
    font-weight: 400;
    font-size: var(--font-size--lg-x);

    aspect-ratio: 1;
    width: 3.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
      margin-top: var(--spacing--20);
      margin-left: var(--spacing--20);
    }
  }
}
