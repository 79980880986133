.priority-tags__container {

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--spacing--20);

	.priority-tag {
		border: 1px solid var(--color--neutral--030);
		padding: var(--spacing--05) var(--spacing--15);
		background-color: var(--color--neutral--030);
		font-size: var(--font-size--sm-xx);
		border-radius: .2rem;
		font-weight: 600;
	}

	.priority-tag--patch {
		background-color: var(--color--info--light);
		border-color: transparent;
		color: white;
	}

	.priority-tag--minor {
		background-color: var(--color--warning--light);
		border-color: transparent;
		color: white;
	}

	.priority-tag--outdated-data,
	.priority-tag--major {
		background-color: var(--color--alert--light);
		border-color: transparent;
		color: white;
	}

}